<template>
  <div>
    <v-app-bar color="primary">
      <v-img src="../assets/logo2.png"></v-img>
      <v-spacer></v-spacer>
      <v-btn icon="mdi-home-outline"></v-btn>
    </v-app-bar>

    <div class="ma-2">
      <web-phone
        :dialExten="getDialExten"
        :exten="exten"
        :identity="identity"
        :hangupCall="hangupCall"
        @registration="setRegState"
        @hangup="hangup"
      />
    </div>
    <v-container class="alotel-menu-container" fill-height>
      <v-row align="center" justify="center" style="height: 200" wrap>
        <v-col v-for="(i, k) in items" :key="k" cols="12" md="4">
          <alotel-option
            :item="i"
            :index="k"
            :registered="registered"
            :hangupCall="getHangupCall"
            @dial="dial"
            @hangup="hangup"
            @mute="mute"
            @muteSpeaker="muteSpeaker"
            @unmute="unmute"
            @unmuteSpeaker="unmuteSpeaker"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AlotelOption from "@/components/AlotelOption.vue";
import WebPhone from "@/components/WebPhone.vue";
import { UserAgent, Inviter, SessionState } from "sip.js";
import { startRingTone, stopRingTone } from "@/plugins/webphone.js";

export default {
  name: "QrPage",
  data: () => ({
    room: "",
    identity: "",
    exten: "",
    dialExten: "",
    registered: false,
    items: [
      {
        title: "Accueil",
        subtitle: "Nous sommes à votre écoute",
        image: "https://sirvoy.fr/wp-content/uploads/2023/02/Front-Desk.jpg",
        exten: "1001",
      },
      {
        title: "Restaurant",
        subtitle: "Reservations et commandes",
        image:
          "https://static-resources-elementor.mirai.com/wp-content/uploads/sites/346/seibal4.jpg",
        exten: "1002",
      },
      {
        title: "Spa & Bien-être",
        subtitle: "Reservez une scéance",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEGSrblipp8vlvNHamF-FrSn4qVxVdE4ZRdGNbk569cUgQacSeaF6Q1CvfqXiAz_OtYww&usqp=CAU",
        exten: "1003",
      },
      {
        title: "Navettes & Véhicules",
        subtitle: "Reservez un VTC",
        image:
          "https://go-sixt.com/wp-content/uploads/2023/01/AUFMACHER-Bild_IHR-FLUGHAFENTRANSFER-MIT-SIXT-RIDE.jpg",
        exten: "2001",
      },
    ],
  }),
  components: {
    AlotelOption,
    WebPhone,
  },
  methods: {
    setRegState(state) {
      console.log("!!!!!!!!!!!!!!");
      console.log(state);
      switch (state.state) {
        case "Registered":
          this.registered = true;
          break;
        default:
          break;
      }
    },

    invite(phoneNumber) {
      this.dialClick++;
      console.log(this.dialClick);

      if (this.dialClick == 1) {
        this.phoneNumber = phoneNumber;
        this.mediaElement = document.getElementById("mediaElement");
        this.remoteStream = new MediaStream();
        this.target = UserAgent.makeURI(
          `sip:${phoneNumber}@maroc-integration.ma`
        );
        const inviter = new Inviter(this.userAgent, this.target);

        inviter.stateChange.addListener((state) => {
          console.log(`Session state changed to ${state}`);
          this.initWatch();
          this.initSession(inviter);
          this.dialog = true;
          switch (state) {
            case SessionState.Initial:
              break;
            case SessionState.Establishing:
              this.incall = true;
              this.status = "RINGING";
              this.ring = startRingTone();
              break;
            case SessionState.Established:
              stopRingTone(this.ring);
              this.status = "CONNECTED";
              this.setupRemoteMedia(inviter);
              break;
            case SessionState.Terminating:
            // fall through
            case SessionState.Terminated:
              stopRingTone(this.ring);
              this.incall = false;
              this.dialClick = 0;
              this.status = "DISCONNECTED";
              setTimeout(() => {
                this.status = "";
                this.phoneNumber = "";
              }, 2000);
              this.cleanupMedia();
              this.hangup();
              break;
            default:
              throw new Error("Unknown session state.");
          }
        });
        inviter.invite();
      }
    },

    dial(exten) {
      console.log("Dial event received for extension: " + exten);
      this.exten = exten;
      this.dialExten = true;
      this.hangupCall = false;
      //this.invite(exten);
    },

    hangup() {
      console.log("Hangup event received");
      this.exten = null;
      this.dialExten = false;
      this.hangupCall = true;
    },

    muteSpeaker() {
      this.speakerOff = true;
    },

    unmuteSpeaker() {
      this.speakerOff = false;
    },

    mute() {
      this.muted = true;
    },

    unmute() {
      this.muted = false;
    },
  },
  setup() {},
  mounted() {
    this.room = this.$route.query.room;
    this.identity = this.$route.query.identity;
  },
  computed: {
    getDialExten() {
      return this.dialExten;
    },
    getHangupCall() {
      return this.hangupCall;
    },
  },
  watch: {
    dialExten: {
      immediate: true,
      handler(n) {
        return n;
      },
    },
    exten: {
      immediate: true,
      handler(n) {
        return n;
      },
    },
    identity: {
      immediate: true,
      handler(n) {
        return n;
      },
    },
    hangupCall: {
      immediate: true,
      handler(n) {
        this.hangupCall = n;
      },
    },
  },
};
</script>
